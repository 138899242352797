import React from 'react'
import '../Customers.css'
import Cards from '../Cards'

export default function Customers() {
  return (
    // <div className='cards'>
    //   <h1>Our Valued Customers</h1>
    //   <div className='cards__container'>
    //     <div className='cards__wrapper'>
    //       <ul className='cards__items'>
    //         <CardItem
    //           src='/images/customers_logo/aurobindo.jpg'
    //           text=''
    //           path='/'
    //         // label=''
    //         />
    //         <CardItem
    //           src='/images/customers_logo/coromandel.jpg'
    //           text=''
    //           // label=''
    //           path='/'
    //         />
    //       </ul>
    //       <ul className='cards__items'>
    //         <CardItem
    //           src='/images/customers_logo/drl.jpg'
    //           text=''

    //           path='/'
    //         />
    //         <CardItem
    //           src='/images/customers_logo/eisai.jpg'
    //           text=''

    //           path='/products'
    //         />
    //         <CardItem
    //           src='/images/customers_logo/hetero.jpg'
    //           text=''

    //           path='/sign-up'
    //         />
    //       </ul>
    //       <ul className='cards__items'>
    //         <CardItem
    //           src='/images/customers_logo/hormann.jpg'
    //           text=''

    //           path='/'
    //         />
    //         <CardItem
    //           src='/images/customers_logo/kaveri.jpg'
    //           text='
    //           '

    //           path='/products'
    //         />
    //         <CardItem
    //           src='/images/customers_logo/mrf.jpg'
    //           text=''

    //           path='/sign-up'
    //         />
    //       </ul>
    //       <ul className='cards__items'>
    //         <CardItem
    //           src='/images/customers_logo/msn.jpg'
    //           text=''

    //           path='/'
    //         />
    //         <CardItem
    //           src='/images/customers_logo/paradise.jpg'
    //           text=''

    //           path='/products'
    //         />

    //       </ul>
    //     </div>
    //   </div>
    // </div>
    <Cards />
  )
}
